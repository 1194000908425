import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  IconButton,
  Typography,
} from "@mui/material";
import SubmitButton from "components/Buttons/SubmitButton";
import IconifyIcon from "components/icon";
import React from "react";

function ModalWithFooter({
  isOpen,
  handleSubmit,
  title,
  btnName,
  modalContent,
  handleModelClose,
  maxWidth,
  btnDisable,
  showSingleBtn = false,
  formError,
  isSubmitting,
  secondarybtnName,
}) {
  return (
    <Dialog
      open={isOpen}
      onClose={() => {}}
      aria-describedby="alert-dialog-slide-description"
      aria-labelledby="alert-dialog-title"
      maxWidth={maxWidth}
      fullWidth={true}
      scroll="paper"
    >
      <DialogTitle
        id="customized-dialog-title"
        textAlign={"left"}
        sx={{ p: 4 }}
      >
        <Typography variant="h6" component="span" color={"#0002D2"}>
          {title}
        </Typography>
        <IconButton
          id="closeButton"
          aria-label="close"
          onClick={handleModelClose}
          sx={{ top: 10, right: 10, position: "absolute", color: "grey.500" }}
        >
          <IconifyIcon icon="mdi:close" />
        </IconButton>
      </DialogTitle>
      <form noValidate onSubmit={handleSubmit}>
        <DialogContent dividers sx={{ p: 6 }}>
          {modalContent}
        </DialogContent>
        <DialogActions
          className="dialog-actions-dense"
          sx={{ p: (theme) => `${theme.spacing(3)} !important` }}
        >
          {/* {formError && (
          <FormHelperText
            sx={{ color: "error.main" }}
            // className="mr-5 flex items-end"
          >
            {formError}
          </FormHelperText>
        )} */}
          <Button
            id="cancelButton"
            variant="outlined"
            onClick={handleModelClose}
          >
            {secondarybtnName ? secondarybtnName : "Cancel"}
          </Button>
          {!showSingleBtn && (
            <SubmitButton
              id="submitButton"
              disabledBtn={btnDisable}
              isSubmitting={isSubmitting}
              buttonLabel={btnName ? btnName : "Submit"}
              fullWidth={false}
            />
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default ModalWithFooter;
