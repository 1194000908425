import { Box, Button, CircularProgress } from "@mui/material";
import React from "react";

const SubmitButton = ({
  disabledBtn,
  isSubmitting,
  buttonLabel,
  fullWidth = true,
  marginButton,
  id,
}) => {
  return (
    <Button
      fullWidth={fullWidth}
      id={id}
      disabled={disabledBtn}
      size="large"
      type="submit"
      variant="contained"
      sx={{
        pointerEvents: disabledBtn ? "none" : "",
        mb: marginButton,
      }}
    >
      {isSubmitting ? (
        <CircularProgress sx={{ color: "#fff" }} size={25} />
      ) : buttonLabel ? (
        buttonLabel
      ) : (
        "Submit"
      )}
    </Button>
  );
};

export default SubmitButton;
