import axios from "axios";
import { apiHost } from "../../../config";
import { getCookie, setCookie } from "utils/cookies";
import jwtDecode from "jwt-decode";
import auth from "configs/auth";
import { errorHandler } from "utils/errorHandler";
import * as Sentry from "@sentry/nextjs";

const connection = axios.create({
  baseURL: apiHost,
  headers: {
    "Content-Type": "application/json",
    'Sec-Fetch-Mode': 'cors',
  },
  formHeaders: { "Content-Type": "multipart/form-data" },
});

connection.interceptors.request.use(
  async (config) => {
    if (await validateToken()) {
      const storedToken = getCookie(auth?.accessTokenKeyName);
      const baseToken = getCookie("base64");
      let r = {
        Authorization: baseToken ? `base64 ${baseToken}` : `JWT ${storedToken}`,
      };
      if (storedToken || baseToken) {
        if (config.data instanceof FormData) {
          config.headers = { ...r, ...config.formHeaders };
        } else {
          config.headers = { ...r, ...config.headers };
        }
      }
    }
    return config;
  },
  (error) => {
    Sentry.captureException(error);
    return Promise.reject(error);
  }
);

connection.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const errorMessage = error?.response || error;
    const message =
      errorMessage?.data?.data?.[0] ||
      errorMessage?.data?.error ||
      errorMessage?.data?.message ||
      errorMessage?.message;
    // throw new Error(error);

    Sentry.captureException(error);
    return Promise.reject(error);
  }
);

export default connection;

export async function validateToken() {
  const token = getCookie(auth.accessTokenKeyName);

  const refreshToken = await window.localStorage.getItem(
    auth.refreshTokenKeyName
  );
  if (!token || !refreshToken) {
    return false;
  }

  const status = await decodeWithPub(token);
  if (status) {
    return true;
  }

  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  let refreshTokenStatus = false;

  await fetch(`${apiHost}/auth/refresh`, {
    headers,
    method: "PUT",
    body: JSON.stringify({
      refreshToken: refreshToken,
    }),
  })
    .then(async (response) => {
      if (response.ok) {
        return response.json();
      }
      const errorMessage = [response.status, await response.json()];
      throw errorMessage;
    })
    .then(async ({ data }) => {
      refreshTokenStatus = true;
      setCookie(auth?.accessTokenKeyName, data?.accessToken);
      window.localStorage.setItem(auth.refreshTokenKeyName, data.refreshToken);
    })
    .catch((error) => {
      if (error?.response) {
        errorHandler(error.response);
      } else {
        errorHandler(error);
      }
      throw new Error(error);
    });
  return refreshTokenStatus;
}

export async function decodeWithPub(jwtToken) {
  try {
    const decodedToken = jwtDecode(jwtToken);
    if (decodedToken.exp < Math.floor(Date.now() / 1000) + 1 * 60) {
      return false;
    } else {
      return true;
    }
  } catch (err) {
    return false;
  }
}
