export const getCookie = (name) => {
  if (typeof document !== "undefined") {
    let cookie = document.cookie.match("(^|;) ?" + name + "=([^;]*)(;|$)");
    return cookie && cookie[2];
  }
};

export const setCookie = async (name, value, days = 0, hours = 0) => {
  let date = new Date();
  if (days || hours) {
    date.setTime(
      date.getTime() + hours * 60 * 60 * 1000 + 24 * 60 * 60 * 1000 * days
    );
    document.cookie =
      name + "=" + value + ";path=/;expires=" + date.toGMTString();
  } else {
    document.cookie = name + "=" + value + ";path=/";
  }
};

export const popCookie = (name) => {
  let value = getCookie(name);
  deleteCookie(name);
  return value;
};

export const deleteCookie = (name) => {
  setCookie(name, "", -1);
};

export const deleteAllCookie = () => {
  document.cookie.split(";").forEach((c) => {
    document.cookie = c
      .replace(/^ +/, "")
      .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
  });
};
