import config from "./config.json";

export const fileHost = config.fileHost;
export const apiHost = config.apiHost;
export const qrHost = config.qrHost;
export const explainerHost = config.explainerHost;
export const mapAPIKey = config.mapAPIKey;
export const reportHost = config.reportHost;
export const publishableKey = config.publishableKey;
export const uploadHost = config.uploadHost;
export const shopHost = config.shopHost;
export const sentry_auth = config.sentry_auth;
export const siteHost = config.siteHost;
export const siteName = config.siteName;
export const environment = config.environment;
export const googleSecretID = config.GOOGLE_CLIENT_SECRET;
export const googleClientID = config.GOOGLE_CLIENT_ID;
