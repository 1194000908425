// ** React Imports
import { useEffect, useState } from 'react'

// ** Import context
import { useAuth } from 'utils/hooks/useAuth'

const CanViewNavGroup = props => {
  // ** Props
  const { children, navGroup } = props

  const auth = useAuth()

  // ** Hooks
  const [showItem, setShowItem] = useState(false)

  useEffect(() => {
    // const show = navGroup.children.some(navLink => {
    //   return Object.keys(navLink.permissions).every(permission => {
    //     return (
    //       Object.keys(auth.roles).includes(permission) &&
    //       navLink.permissions[permission].every(subPermission => {
    //         return auth.roles[permission].includes(subPermission)
    //       })
    //     )
    //   });
    // })

    setShowItem(true)
  }, [])

  return (
    showItem ? <>{children}</> : <></>
  )
}

export default CanViewNavGroup
