import React from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Typography } from "@mui/material";
import TextInput from "components/inputs/TextInput";
import ModalWithFooter from "components/modal/ModalWithFooter";

const defaultValues = {
  name: "",
};

const schema = yup.object().shape({
  name: yup.string().required("Please fill the organization name"),
});

const OrganizationCreateForm = ({
  isOpen,
  disableBtn,
  formError,
  handleClose,
  handleCreate,
  isSubmitting,
  setDisableBtn,
}) => {
  const {
    control: inviteControl,
    handleSubmit: handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
  });

  const createForm = () => {
    return (
      <div style={{ display: "flex", flexDirection: "column", gap: "2px" }}>
        <Typography variant="p" className="text-[15px] pb-5">
          Please provide the name of the organization you'd like to create.
        </Typography>
        <Box>
          <Controller
            name="name"
            control={inviteControl}
            rules={{ required: true }}
            render={({ field: { value, onChange, onBlur } }) => (
              <TextInput
                fullWidth
                value={value}
                label={"Organization Name"}
                onBlur={onBlur}
                onChange={(e) => {
                  onChange(e);
                  setDisableBtn(false);
                }}
                error={Boolean(errors.name)}
                helperText={Boolean(errors.name) ? errors.name.message : ""}
              />
            )}
          />
        </Box>
      </div>
    );
  };

  return (
    <ModalWithFooter
      isOpen={isOpen}
      handleModelClose={handleClose}
      title={"Create Organization"}
      btnName={"Create"}
      maxWidth={"sm"}
      modalContent={createForm()}
      handleSubmit={handleSubmit(handleCreate)}
      btnDisable={disableBtn}
      formError={formError}
      isSubmitting={isSubmitting}
    />
  );
};

export default OrganizationCreateForm;
