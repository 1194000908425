import connection from ".";

export const getUserAPI = async (params) => {
  return connection.get(`/users`, { params });
};

export const getUserInviteAPI = async (params) => {
  return connection.get(`/users/invited`, { params });
};


export const userCreateAPI = async (body) => {
  return connection.post("/users", body);
};

export const userUpdateAPI = async (user_id, body) => {
  return connection.put(`/users/${user_id}`, body);
};

export const contractorSetupAPI = async (id, body) => {
  return connection.put(`/users/${id}/setup`, body);
};

export const contractorJoinAPI = async (id, body) => {
  return connection.put(`/users/${id}/join`, body);
};

export const contractorDetailAPI = async (id, body) => {
  return connection.get(`/users/${id}/details`, body);
};

export const adminJoinAPI = async (body) => {
  return connection.put(`/users/invite`, body);
}

export const revertUserInviteAPI = (id) => {
  return connection.put(`/users/${id}/revoke-invite`);
};

export const userSwitchAPI = (id) => {
  return connection.put(`/users/switch/${id}`);
};

