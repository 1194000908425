
import { useRouter } from "next/router";
import React, { createContext, useState, useContext, useEffect } from "react";

const searchContext = createContext();

export function useAppSearch() {
    return useContext(searchContext);
}

export function SearchProvider({ children }) {
    const router = useRouter()
    const [showSearch, setShowSearch] = useState(false);
    const [searchValue, setSearchValue] = useState("");

    useEffect(() => {
        setSearchValue("")
    }, [router])

    const handleSearch = (value) => {
        setSearchValue(value);
    };

    const handleShowSearch = (show) => {
        setShowSearch(show);
    };

    return (
        <searchContext.Provider
            value={{ searchValue, handleSearch, showSearch, handleShowSearch, setSearchValue }}
        >
            {children}
        </searchContext.Provider>
    );
}