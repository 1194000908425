import React, { useState } from "react";
import {
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
} from "@mui/material";
import IconifyIcon from "../icon";

const TextInput = ({
  type = "text",
  label,
  name,
  variant,
  value,
  required,
  disabled,
  readOnly,
  shrink,
  error,
  helperText,
  multiline,
  icon,
  tagPosition,
  customTag,
  focused,
  fullWidth,
  maxRows,
  ref,
  onChange,
  placeholder,
  size,
  rows,
  style,
  onBlur,
  maxLength,
  verified,
  handleVerify,
  showCheck,
  id,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const positionProps =
    tagPosition == "start" ? "startAdornment" : "endAdornment";
  return (
    <TextField
      type={showPassword ? "text" : type}
      variant={variant}
      label={label}
      name={name}
      value={value}
      required={required}
      disabled={disabled}
      readOnly={readOnly}
      shrink={shrink}
      error={error}
      id={id}
      helperText={helperText}
      multiline={multiline}
      focused={focused}
      fullWidth={fullWidth}
      inputRef={ref}
      maxRows={maxRows}
      rows={rows}
      placeholder={placeholder}
      size={size}
      onBlur={onBlur}
      InputProps={{
        [positionProps]: (
          <InputAdornment position={tagPosition}>
            {type == "password" ? (
              <IconButton
                onMouseDown={(e) => e.preventDefault()}
                onClick={() => setShowPassword(!showPassword)}
              >
                {IconifyIcon({
                  icon: showPassword
                    ? "mdi:eye-outline"
                    : "mdi:eye-off-outline",
                  fontSize: 20,
                })}
              </IconButton>
            ) : icon ? (
              IconifyIcon({ icon })
            ) : (
              customTag
            )}
            {verified && (
              <Button size="small" onClick={handleVerify}>
                Verify
              </Button>
            )}
            {showCheck && (
              <IconifyIcon
                icon={"mdi-checkbox-marked-circle-outline"}
                color={"#59B259"}
              />
            )}
          </InputAdornment>
        ),
      }}
      style={style}
      onChange={onChange}
      inputProps={{ maxLength: maxLength }}
    />
  );
};

export default TextInput;
