import { deleteAllCookie } from "./cookies";
import authConfig from "configs/auth";


export const kickUser = async () => {

  localStorage.removeItem("userData");
  localStorage.removeItem(authConfig.refreshTokenKeyName);
  localStorage.removeItem(authConfig.rolesStorageKeyName);
  localStorage.removeItem("current-role");
  deleteAllCookie();
  if (!window.location.pathname.includes("login")) {
    window.location.replace('/login')

  }
};
