// ** Next Import
import Link from "next/link";

// ** MUI Imports
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import { styled, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

// ** Custom Icon Import
import Icon from "components/icon";

// ** Configs
import themeConfig from "configs/themeConfig";
import Image from "next/image";
import logo from "../../../../../../public/images/video_brochure.png";
import logoDark from "../../../../../../public/images/video_brochure_white.png";
import { useAuth } from "utils/hooks/useAuth";

// ** Styled Components
const MenuHeaderWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  paddingRight: theme.spacing(4.5),
  transition: "padding .25s ease-in-out",
  minHeight: theme.mixins.toolbar.minHeight,
}));

const HeaderTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  lineHeight: "normal",
  textTransform: "uppercase",
  color: theme.palette.text.primary,
  transition: "opacity .25s ease-in-out, margin .25s ease-in-out",
}));

const StyledLink = styled(Link)({
  display: "flex",
  alignItems: "center",
  textDecoration: "none",
});

const VerticalNavHeader = (props) => {
  // ** Props
  const {
    hidden,
    navHover,
    settings,
    saveSettings,
    collapsedNavWidth,
    toggleNavVisibility,
    navigationBorderWidth,
    menuLockedIcon: userMenuLockedIcon,
    navMenuBranding: userNavMenuBranding,
    menuUnlockedIcon: userMenuUnlockedIcon,
  } = props;
  const { domain, siteLogo } = useAuth();
  const useLogo = domain ? "videoCatalog" : "adaptiveFunnels";
  const theme = useTheme();
  const { navCollapsed } = settings;
  const menuCollapsedStyles =
    navCollapsed && !navHover ? { opacity: 0 } : { opacity: 1 };

  const menuHeaderPaddingLeft = () => {
    if (navCollapsed && !navHover) {
      if (userNavMenuBranding) {
        return 0;
      } else {
        return (collapsedNavWidth - navigationBorderWidth - 30) / 8;
      }
    } else {
      return 6;
    }
  };

  const svgRotationDeg = () => {
    if (navCollapsed) {
      if (navHover) {
        return 0;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  };

  return (
    <MenuHeaderWrapper
      className="nav-header"
      sx={{ pl: menuHeaderPaddingLeft() }}
    >
      {userNavMenuBranding ? (
        userNavMenuBranding(props)
      ) : (
        <StyledLink href="/" sx={{ alignItems: "end", py: 5 }}>
          <Image
            src={siteLogo}
            alt={`${useLogo}-logo`}
            height={180}
            width={180}
          />
          {/* <HeaderTitle variant='h6' sx={{ ...menuCollapsedStyles, ...(navCollapsed && !navHover ? {} : { ml: 3 }) }}>
          </HeaderTitle> */}
        </StyledLink>
      )}

      {/* {hidden ? (
        <IconButton
          disableRipple
          disableFocusRipple
          onClick={toggleNavVisibility}
          sx={{ p: 0, backgroundColor: 'transparent !important' }}
        >
          <Icon icon='mdi:close' fontSize={20} />
        </IconButton>
      ) : userMenuLockedIcon === null && userMenuUnlockedIcon === null ? null : (
        <IconButton
          disableRipple
          disableFocusRipple
          onClick={() => saveSettings({ ...settings, navCollapsed: !navCollapsed })}
          sx={{
            p: 0,
            color: 'text.primary',
            backgroundColor: 'transparent !important',
            '& svg': {
              fontSize: '1.25rem',
              ...menuCollapsedStyles,
              transition: 'opacity .25s ease-in-out'
            }
          }}
        >
          {(!navCollapsed || navHover) && <Box
            sx={{
              display: "flex",
              borderRadius: 5,
              backgroundColor: "primary.main",
              color: "#fff",
              "& svg": {
                transform: `rotate(${svgRotationDeg()}deg)`,
                transition: "transform .25s ease-in-out .35s",
              },
            }}
          >
            <Icon icon="bx:chevron-left" />
          </Box>}
        </IconButton>
      )} */}
    </MenuHeaderWrapper>
  );
};

export default VerticalNavHeader;
