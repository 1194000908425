// ** React Imports
import { useState } from "react";

// ** MUI Imports
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";

// ** Theme Config Import
import themeConfig from "configs/themeConfig";

// ** Components
import AppBar from "./components/vertical/appBar";
import Navigation from "./components/vertical/navigation";
import { Alert, AlertTitle, Button } from "@mui/material";
import { errorHandler } from "utils/errorHandler";
import { AddCardDetails } from "pages/api/subscription";
import { useAuth } from "utils/hooks/useAuth";

const VerticalLayoutWrapper = styled("div")({
  height: "100%",
  display: "flex",
});

const MainContentWrapper = styled(Box)({
  flexGrow: 1,
  minWidth: 0,
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
});

const ContentWrapper = styled("main")(({ theme }) => ({
  flexGrow: 1,
  width: "100%",
  padding: theme.spacing(6),
  transition: "padding .25s ease-in-out",
  [theme.breakpoints.down("sm")]: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
}));

const VerticalLayout = (props) => {
  // ** Props
  const { settings, children, contentHeightFixed, verticalLayoutProps } = props;
  const auth = useAuth();
  const { user } = auth;
  // ** Vars
  const { skin, navHidden, contentWidth } = settings;
  const { navigationSize, collapsedNavigationSize } = themeConfig;
  const navWidth = navigationSize;
  const navigationBorderWidth = skin === "bordered" ? 1 : 0;
  const collapsedNavWidth = collapsedNavigationSize;
  // ** States
  const [navVisible, setNavVisible] = useState(false);
  const currentDate = new Date();
  const endDate = new Date(user?.subscription?.endDate);

  const timeDifference = endDate.getTime() - currentDate.getTime();
  const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));
  const showCard =
    user?.activeContractor?.paymentMethod === false &&
    (!user?.subscription?.scheduled && user?.subscription?.autoRenewal
      ? daysDifference >= 0 && daysDifference <= 3
      : false);
  const handleCard = async () => {
    AddCardDetails()
      .then(async ({ data }) => {
        if (data.data?.url) {
          window.open(data.data.url, "_blank");
        }
      })
      .catch((error) => {
        if (error.response) {
          errorHandler(error.response);
        } else {
          errorHandler(error);
        }
      });
  };
  // ** Toggle Functions
  const toggleNavVisibility = () => setNavVisible(!navVisible);
  return (
    <>
      <VerticalLayoutWrapper className="layout-wrapper">
        {navHidden ? null : (
          <Navigation
            navWidth={navWidth}
            navVisible={navVisible}
            setNavVisible={setNavVisible}
            collapsedNavWidth={collapsedNavWidth}
            toggleNavVisibility={toggleNavVisibility}
            navigationBorderWidth={navigationBorderWidth}
            navMenuContent={verticalLayoutProps.navMenu.content}
            navMenuBranding={verticalLayoutProps.navMenu.branding}
            menuLockedIcon={verticalLayoutProps.navMenu.lockedIcon}
            verticalNavItems={verticalLayoutProps.navMenu.navItems}
            navMenuProps={verticalLayoutProps.navMenu.componentProps}
            menuUnlockedIcon={verticalLayoutProps.navMenu.unlockedIcon}
            afterNavMenuContent={verticalLayoutProps.navMenu.afterContent}
            beforeNavMenuContent={verticalLayoutProps.navMenu.beforeContent}
            {...props}
          />
        )}
        <MainContentWrapper
          className="layout-content-wrapper"
          sx={{ ...(contentHeightFixed && { maxHeight: "100vh" }) }}
        >
          {showCard && (
            <Alert
              severity="warning"
              sx={{ my: 4, mx: 6 }}
              action={
                <Button
                  color="warning"
                  variant="contained"
                  size="small"
                  onClick={handleCard}
                >
                  add card
                </Button>
              }
            >
              <AlertTitle
                sx={{
                  fontWeight: 600,
                  mb: (theme) => `${theme.spacing(1)} !important`,
                }}
              >
                Your card details are missing. Please add them for the next
                payment.
              </AlertTitle>
            </Alert>
          )}
          <AppBar
            toggleNavVisibility={toggleNavVisibility}
            appBarContent={verticalLayoutProps.appBar?.content}
            appBarProps={verticalLayoutProps.appBar?.componentProps}
            {...props}
          />

          <ContentWrapper
            className="layout-page-content"
            sx={{
              ...(contentHeightFixed && {
                overflow: "hidden",
                "& > :first-of-type": { height: "100%" },
              }),
              // ...(contentWidth === "boxed" && {
              //   mx: "auto",
              //   "@media (min-width:1440px)": { maxWidth: 1440 },
              //   "@media (min-width:1200px)": { maxWidth: "100%" },
              // }),
              ...{
                mx: "auto",
                "@media (min-width:1440px)": { maxWidth: 1440 },
                "@media (min-width:1200px)": { maxWidth: "100%" },
              },
            }}
          >
            {children}
          </ContentWrapper>
        </MainContentWrapper>
      </VerticalLayoutWrapper>
    </>
  );
};

export default VerticalLayout;
