// ** React Imports
import { useEffect } from "react";

// ** Next Imports
import { useRouter } from "next/router";

// ** Hooks Import
import { useAuth } from "utils/hooks/useAuth";

const GuestGuard = (props) => {
  const { children, fallback } = props;
  const auth = useAuth();
  const router = useRouter();

  useEffect(() => {
    if (!router.isReady) {
      return;
    }
    const userData = auth.user
      ? auth.user
      : JSON.parse(window.localStorage.getItem("userData"));
    if (auth.user && window.localStorage.getItem("userData")) {
      if (userData?.setupStatus == "pending") {
        router.push("/register");
        return;
      }
    }
    // if (userData?.setupStatus === "registered") {
    //   router.push("/pricing");
    //   return;
    // }
    if (window.localStorage.getItem("userData")) {
      router.replace("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.route]);

  if (
    (auth.loading && router.asPath !== "/register") ||
    (!auth.loading && auth.user !== null && router.asPath !== "/register" && router.pathname !== '/verify')
  ) {
    return fallback;
  }

  return <>{children}</>;
};

export default GuestGuard;
