import connection from ".";

export const purchaseLicense = async (body) => {
  return connection.post("/licenses", body);
};

export const redeemCode = async (body) => {
  return connection.put("/subscriptions/redeem-code", body);
};

export const listLicense = async (params) => {
  return connection.get(`/subscriptions`, { params });
};

export const assignTechAPI = async (sub_id, body) => {
  return connection.put(`/subscriptions/${sub_id}/assign`, body);
};

export const unAssignTechAPI = async (sub_id, body) => {
  return connection.put(`/subscriptions/${sub_id}/unassign`, body);
};

export const listenPayment = async (id) => {
  return connection.get(`/licenses/${id}`);
};

export const createSubscription = async (body) => {
  return connection.post("/users/subscriptions", body);
};

export const AddCardDetails = async () => {
  return connection.post("/users/subscriptions/addpaymentmethod");
};

export const updateSubscription = async (body) => {
  return connection.put("/users/subscriptions/upgrade-plan", body);
};

export const createSubscriptions = async (body) => {
  return connection.post("/users/subscriptions", body);
};

export const cancelSubscriptions = async () => {
  return connection.put(`/users/subscriptions/cancel`);
};

export const subscriptionsCallback = async (id) => {
  return connection.get(`/users/subscriptions/check/${id}`);
};
