// ** React Imports
import { useState, Fragment } from "react";

// ** MUI Imports
import Box from "@mui/material/Box";
import Badge from "@mui/material/Badge";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import MuiMenu from "@mui/material/Menu";
import MuiMenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";

// ** Third Party Components
import PerfectScrollbarComponent from "react-perfect-scrollbar";

// ** Custom Components Imports

// ** Util Import
// import { getInitials } from "src/@core/utils/get-initials";
import IconifyIcon from "components/icon";
import CustomChip from "components/mui/chip";
import Link from "next/link";
import { Tooltip, Zoom } from "@mui/material";
// import Avatar from "components/mui/avatar";

// ** Styled Menu component
const Menu = styled(MuiMenu)(({ theme }) => ({
  "& .MuiMenu-paper": {
    width: 380,
    overflow: "hidden",
    marginTop: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  "& .MuiMenu-list": {
    padding: 0,
  },
}));

// ** Styled MenuItem component
const MenuItem = styled(MuiMenuItem)(({ theme }) => ({
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  "&:not(:last-of-type)": {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));

// ** Styled PerfectScrollbar component
const PerfectScrollbar = styled(PerfectScrollbarComponent)({
  maxHeight: 349,
});

// ** Styled Avatar component
// const Avatar = styled(Avatar)({
//   width: 38,
//   height: 38,
//   fontSize: "1.125rem",
// });

// ** Styled component for the title in MenuItems
const MenuItemTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  flex: "1 1 100%",
  overflow: "hidden",
  fontSize: "0.875rem",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  marginBottom: theme.spacing(0.75),
}));

// ** Styled component for the subtitle in MenuItems
const MenuItemSubtitle = styled(Typography)({
  flex: "1 1 100%",
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
});

const ScrollWrapper = ({ children }) => {
  return (
    <Box sx={{ maxHeight: 300, overflowY: "auto", overflowX: "hidden" }}>
      {children}
    </Box>
  );
};

const guideDropDownData = [
  {
    link: "https://app.sharefable.com/live/demo/static-full-funnel-cre-17f2yp7gxrhablir",
    buttonName: "Learn Now",
    title: "Create a Static funnel",
  },
  {
    link: "https://app.sharefable.com/live/demo/dynamic-full-flow-6i5fmvx3ytfsai34",
    buttonName: "Learn Now",
    title: "Create a Dynamic Funnel",
  },
  {
    link: "https://app.sharefable.com/live/demo/contact-menu-b8ibgzau6grn80a5",
    buttonName: "Learn Now",
    title: "Create a Contact",
  },
  {
    link: "https://app.sharefable.com/live/demo/form-uyvuhlzih4r7zp6j",
    buttonName: "Learn Now",
    title: "Create an Engagement Form",
  },
  {
    link: "https://app.sharefable.com/live/demo/cookies-form-a01p7zxseunk21fz",
    buttonName: "Learn Now",
    title: "Create a Cookies Form",
  },
  // {
  //   link: "https://app.sharefable.com/live/demo/inbox-menu-p9of95x0xjjufwem",
  //   buttonName: "Learn Now",
  //   title: "Navigate your Inbox",
  // },
];

const GuideDropdown = (props) => {
  // ** Props
  const { settings, notifications } = props;

  // ** States
  const [anchorEl, setAnchorEl] = useState(null);

  // ** Hook
  const hidden = useMediaQuery((theme) => theme.breakpoints.down("lg"));

  // ** Vars
  const { direction } = settings;

  const handleDropdownOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDropdownClose = () => {
    setAnchorEl(null);
  };

  // const RenderAvatar = ({ notification }) => {
  //   const { avatarAlt, avatarImg, avatarIcon, avatarText, avatarColor } =
  //     notification;
  //   if (avatarImg) {
  //     return <Avatar alt={avatarAlt} src={avatarImg} />;
  //   } else if (avatarIcon) {
  //     return (
  //       <Avatar skin="light" color={avatarColor}>
  //         {avatarIcon}
  //       </Avatar>
  //     );
  //   } else {
  //     return (
  //       <Avatar skin="light" color={avatarColor}>
  //         {getInitials(avatarText)}
  //       </Avatar>
  //     );
  //   }
  // };

  return (
    <Fragment>
      <IconButton
        color="inherit"
        aria-haspopup="true"
        onClick={handleDropdownOpen}
        aria-controls="customized-menu"
      >
        {/* <Badge
          color="error"
          variant="dot"
          // invisible={!notifications.length}
          sx={{
            "& .MuiBadge-badge": {
              top: 4,
              right: 4,
              boxShadow: (theme) =>
                `0 0 0 2px ${theme.palette.background.paper}`,
            },
          }}
        > */}
        <IconifyIcon icon="mdi-help-circle-outline" />
        {/* </Badge> */}
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleDropdownClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: direction === "ltr" ? "right" : "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: direction === "ltr" ? "right" : "left",
        }}
      >
        <MenuItem
          disableRipple
          disableTouchRipple
          sx={{
            cursor: "default",
            userSelect: "auto",
            backgroundColor: "transparent !important",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Typography sx={{ cursor: "text", fontWeight: 600 }}>
              Guide
            </Typography>
            <Link
              href={
                "https://adaptivefunnels-community.circle.so/c/knowledge-base"
              }
              target="_blank"
            >
              <Tooltip
                arrow
                title={"Video Guide"}
                placement="top"
                TransitionComponent={Zoom}
                TransitionProps={{ timeout: 500 }}
              >
                <Typography>
                  <IconifyIcon icon={"mdi-message-video"} />
                </Typography>
              </Tooltip>
            </Link>
          </Box>
        </MenuItem>
        <ScrollWrapper hidden={hidden}>
          {guideDropDownData.map((guide, index) => (
            <MenuItem key={index} onClick={handleDropdownClose}>
              <Link href={guide.link} target="_blank" className="w-full">
                <Box
                  sx={{ width: "100%", display: "flex", alignItems: "center" }}
                >
                  <CustomChip
                    skin="light"
                    size="small"
                    color="primary"
                    label={`${index + 1}`}
                    sx={{
                      height: 23,
                      fontSize: "0.75rem",
                      fontWeight: 500,
                      borderRadius: "10px",
                    }}
                  />
                  {/* <RenderAvatar notification={notification} /> */}
                  <Box
                    sx={{
                      mx: 3,
                      flex: "1 1",
                      display: "flex",
                      overflow: "hidden",
                      flexDirection: "column",
                    }}
                  >
                    <MenuItemTitle>{guide.title}</MenuItemTitle>
                    {/* <MenuItemSubtitle variant="body2">
                    {guide.subtitle}
                  </MenuItemSubtitle> */}
                  </Box>
                  {/* <Typography variant="caption" sx={{ color: "text.disabled" }}>
                  {notification.meta}
                </Typography> */}
                  <Link href={guide.link} target="_blank">
                    <IconifyIcon
                      icon={"mdi-open-in-new"}
                      style={{ color: "#0C0D85" }}
                    />
                  </Link>
                </Box>
              </Link>
            </MenuItem>
          ))}
        </ScrollWrapper>
        {/* <MenuItem
          disableRipple
          disableTouchRipple
          sx={{
            py: 3.5,
            borderBottom: 0,
            cursor: "default",
            userSelect: "auto",
            backgroundColor: "transparent !important",
            borderTop: (theme) => `1px solid ${theme.palette.divider}`,
          }}
        >
          <Button fullWidth variant="contained" onClick={handleDropdownClose}>
            Read All Notifications
          </Button>
        </MenuItem> */}
      </Menu>
    </Fragment>
  );
};

export default GuideDropdown;
