import connection from ".";

export const getTechnicianAPI = async () => {
  return connection.get(`/users/list`);
};

export const getTechnicianInviteAPI = async (params) => {
  return connection.get(`/users/admin/invited`, { params });
};

export const getTechnicianActiveAPI = async (params) => {
  return connection.get(`/users/admin/members`, { params });
};

export const createTechnicianAPI = (body) => {
  return connection.put(`/users/admin/invite`, body);
};

export const getTechnicianJoinAPI = async (params) => {
  return connection.put(`/users/${params?.id}/join`);
};

export const getTechnicianDetails = async (params) => {
  return connection.get(`/users/${params.id}/details`);
};

export const getAssessmentAPI = async (params) => {
  return connection.get(`/assessments`, { params });
};

export const revertInviteAPI = (id) => {
  return connection.put(`/users/admin/${id}/revoke-invite`);
};

export const removeTechnicianAPI = (id, body) => {
  return connection.put(`/users/admin/${id}/remove`, body);
};

export const teamUpdateAPI = (user_id, body) => {
  return connection.put(`/users/admin/${user_id}/update`, body);
};

export const updateUserPasswordAPI = (user_id, body) => {
  return connection.put(`/users/admin/${user_id}/change-password`, body);
};

export const createOrganizationAPI = (body) => {
  return connection.post(`/users/contractors`, body);
};
