// // ** React Imports
// import { useEffect, useState } from "react";
// // ** Next Imports
// import { useRouter } from "next/router";
// // ** Hooks Import
// import { useAuth } from "utils/hooks/useAuth";
// import { getCookie, setCookie } from "utils/cookies";

// const AuthGuard = ({ children, fallback, filteredMemberMenu }) => {
//   const auth = useAuth();
//   const router = useRouter();
//   const [allowed, setAllowed] =  useState(false)

//   useEffect(() => {
//     const handleRouteChange = (url) => {
//       if (!router.isReady) return;

//       const userData = auth.user
//         ? auth.user
//         : JSON.parse(window.localStorage.getItem("userData"));

//       if (auth.user === null && !window.localStorage.getItem("userData")) {
//         router.replace({
//           pathname: "/login",
//           query: { returnUrl:  router.asPath },
//         });
//         return;
//       }

//       const isSubscribed = getCookie(userData?.activeContractor?._id);
//       if ( router.asPath !== "/pricing" && !isSubscribed && !auth.loading) {
//         setCookie(userData?.activeContractor?._id, "trail");
//       }

//       const accessibleRoutes = filteredMemberMenu.map((item) => item.path);
//       accessibleRoutes.push('/user-profile');
//       accessibleRoutes.push('//pricing');


//       const isAccessible = accessibleRoutes.some((route) => url.startsWith(route));

//       if (!isAccessible) {
//         const defaultRoute = accessibleRoutes.includes('/dashboard') ? '/dashboard' : accessibleRoutes[0];
//         router.replace(defaultRoute);
//         setAllowed(false)
//       }else {
//         setAllowed(true)
//       }
//     };

//     handleRouteChange(router.pathname); 

//     router.events.on('routeChangeComplete', handleRouteChange); 

//     return () => {
//       router.events.off('routeChangeComplete', handleRouteChange); 
//     };
//   }, [auth.user, router.isReady, filteredMemberMenu]);

//   if (auth.loading || !allowed) {
//     return fallback;
//   }

//   return <>{children}</>;
// };

// export default AuthGuard;
// ** React Imports
import { useEffect } from "react";

// ** Next Imports
import { useRouter } from "next/router";

// ** Hooks Import
import { useAuth } from "utils/hooks/useAuth";
import { getCookie, setCookie } from "utils/cookies";

const AuthGuard = (props) => {
  const { children, fallback } = props;
  const auth = useAuth();
  const router = useRouter();
  useEffect(
    () => {
      const userData = auth.user
        ? auth.user
        : JSON.parse(window.localStorage.getItem("userData"));
      if (!router.isReady) {
        return;
      }
      if (auth.user === null && !window.localStorage.getItem("userData")) {
        if (router.asPath !== "/") {
          router.replace({
            pathname: "/login", 
            query: { returnUrl: router.asPath },
          });
        } else {
          router.replace("/login");
        }
      }
      const isSubscribed = getCookie(userData?.activeContractor?._id);
      if (router.asPath !== "/pricing" && !isSubscribed && !auth.loading) {
        setCookie(userData?.activeContractor?._id, "trail");

      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [router.route]
  );
  if (auth.loading) {
    return fallback;
  }

  return <>{children}</>;
};

export default AuthGuard;
